import React from 'react'
import './ContactContentStyles.css'

// Contact information content

function ContactContent() {
    return (
        <div className='contact-info'>
            <div className='container'>
                <p>Personal email: seanhyu@gmail.com</p>
                <p>School email: seanhyu@uchicago.edu</p>
            </div>
        </div>
    )
}
export default ContactContent